import React, { useEffect } from "react";
// import '../../../assets/css/style.css';
// import '../../../assets/font-awesome.min.css';
import FooterLogo from "../../../assets/images/header-logo.png";
import { Link, useLocation } from "react-router-dom";

const propTypes = {};

const defaultProps = {};
const scrollToTop = () => {
  console.log("object");
  window.scrollTo(0, 0);
};

const Footer = () => {
  return (
    <div>
      {/* <!-- Footer Section :: START --> */}
      <footer className="codex-footer-section">
        <div className="container">
          <div className="row align-items-center margin-bottom-lg-80 margin-bottom-sm-30 margin-bottom-md-30">
            <div className="col-lg-7 col-md-12 col-sm-12">
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  {/* <div className="footer-logo margin-bottom-lg-20">
                    <img src={FooterLogo} alt="Footer Logo" />
                  </div> */}
                  <Link to="/">
                    <div className="footer-logo margin-bottom-lg-20" onClick={scrollToTop}>
                        <img src={FooterLogo} alt="Footer Logo" />
                    </div>
                  </Link>
                  <div className="footer-content">
                    <p className="small-text-16 paragraph-text margin-bottom-lg-20">
                      Wir helfen Ihnen bei der Digitalisierung und Optimierung
                      von Arbeitsabläufen.
                    </p>
                    <p className="small-text-16 paragraph-text copyright-text">
                      © 2023. All rights reserved.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-12 col-sm-12 margin-top-lg-20">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="footer-content">
                    <div className="footer-header margin-bottom-lg-40">
                      <h3 className="small-text-20 sub-title-text">
                        Unternehmen
                      </h3>
                    </div>
                    <div className="footer-links">
                      <ul className="list-unstyled">
                        <li className="margin-bottom-lg-30">
                          <a>
                            <h3 className="small-text-20 link-text">Kontakt</h3>
                          </a>
                        </li>
                        <li className="margin-bottom-lg-30">
                          <div className="list-content">
                            <Link to="/jobs">
                              <h3
                                className="small-text-20 link-text"
                                onClick={scrollToTop}
                              >
                                Karriere
                              </h3>
                            </Link>
                            <a className="footer-btn small-text-16">Suche!</a>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="footer-content">
                    <div className="footer-header margin-bottom-lg-40">
                      <h3 className="small-text-20 sub-title-text">
                        Unser Angebot
                      </h3>
                    </div>
                    <div className="footer-links">
                      <ul className="list-unstyled">
                        <li className="margin-bottom-lg-30">
                          <a>
                            <h3 className="small-text-20 link-text">
                              Dienstleistungen
                            </h3>
                          </a>
                        </li>
                        <li className="margin-bottom-lg-30">
                          <a>
                            <h3 className="small-text-20 link-text">
                              Services
                            </h3>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- footer-extra-links --> */}
          <div className="row footer-extra-links align-items-lg-end">
            <div className="col-xl-2 col-lg-4 col-md-4 col-sm-12 margin-bottom-lg-10">
              <div className="footer-extra-link">
                <a>Historie der Privatsphäre</a>
              </div>
            </div>
            <div className="col-xl-2 col-lg-4 col-md-4 col-sm-12 margin-bottom-lg-10">
              <div className="footer-extra-link">
                <a>Einwilligung widerrufen</a>
              </div>
            </div>
            <div className="col-xl-2 col-lg-4 col-md-4 col-sm-12 margin-bottom-lg-10">
              <div className="footer-extra-link">
                <a>Datenschutzerklärung</a>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-4 col-sm-12 margin-bottom-lg-10">
              <div className="footer-extra-link">
                <a>Privatsphäreeinstellungen ändern</a>
              </div>
            </div>
            <div className="col-xl-1 col-lg-4 col-md-4 col-sm-12 margin-bottom-lg-10">
              <Link to="/impressum">
                <div className="footer-extra-link" onClick={scrollToTop}>
                  Impressum
                </div>
              </Link>
            </div>
            <div className="col-xl-2 col-lg-4 col-md-4 col-sm-12 d-flex justify-content-xl-end margin-bottom-lg-10">
              <div className="social-media-link">
                <p className="small-text-14 margin-bottom-lg-10">
                  Stay in Touch
                </p>
                <div className="footer-extra-link">
                  <a>
                    <i className="fa fa-instagram" aria-hidden="true"></i>
                  </a>
                  <a>
                    <i className="fa fa-linkedin-square" aria-hidden="true"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- footer-extra-links --> */}
        </div>
      </footer>
      {/* <!-- Footer Section :: END --> */}
    </div>
  );
};

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;
// #endregion

export default Footer;
