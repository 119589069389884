import React from 'react';
import Routs from './Routs'
import ScrollToTop from './ScrollToTop ';
const propTypes = {};

const defaultProps = {};

/**
 * 
 */
const App = () => {
  return <div>
     {/* <Header/> */}
     <ScrollToTop />
       <Routs />
       {/* <HomeCompo/> 
       <Impressum/>
       <Job/> */}
     {/* <Footer/> */}
  </div>;
}

App.propTypes = propTypes;
App.defaultProps = defaultProps;
// #endregion

export default App;