import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  BrowserRouter,
  Route,
} from "react-router-dom";
import HomeCompo from "./components/HomeCompo/HomeCompo";
import Impressum from "./components/Impressum/Impressum";
import Jobs from "../src/components/Jobsv1/jobs";
const propTypes = {};
const defaultProps = {};

const Routs = () => {
  return (
    <Routes>
      <Route exact path="/" element={<HomeCompo />} />
      <Route exact path="/impressum" element={<Impressum />} />
      <Route exact path="/jobs" element={<Jobs />} />
      {/* <Route exact path="/careers" element={<Careers />} /> */}
    </Routes>
  );
};

Routs.propTypes = propTypes;
Routs.defaultProps = defaultProps;
export default Routs;
