import React from 'react';
import Header from '../common/Header/Header';
import Footer from '../common/Footer/Footer';
import CookiesModal from '../common/CookiesModal/CookiesModal';
// import '../../assets/css/style.css';
// import '../../assets/css/responsive.css';
// import '../../assets/css/bootstrap-css/bootstrap.min.css'
const propTypes = {};

const defaultProps = {};

const HomeCompo = () => {
    return <div>
        {/* <!-- Banner Section :: START --> */}
        <Header />
        <div className="codex-banner-section margin-bottom-lg-80 margin-bottom-md-60 margin-bottom-sm-60">
            <div className="container margin-top-lg-80 margin-top-md-20">
                <div className="row d-flex justify-content-end">
                    <div className="col-lg-8 col-md-8 col-sm-12 p-0">
                        <div className="title-section">
                            <h2 className="heading-text-84 title-text">Die Zukunft</h2>
                        </div>
                    </div>
                </div>
                <div className="row flex-column-reverse flex-lg-row flex-md-row">
                    <div className="col-lg-7 col-md-6 col-sm-12 p-0">
                        <div className="codex-banner-img">
                            <img src="assets/images/Website-Bilder/Homepage/computer-banner.png" alt="Banner Image" />
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-6 col-sm-12 p-0">
                        <div className="banner-content">
                            <div className="title-section">
                                <h2 className="heading-text-84 title-text">ist digital.</h2>
                            </div>
                            <div className="margin-top-lg-30">
                                <p className="small-text-20 bold-paragraph-text">Digitalisierung von der Idee bis in die Anwendung</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-lg-end code-the-way-row">
                    <div className="col-xl-9 col-lg-12 col-md-12 col-sm-12">
                        <div className="row justify-content-lg-end">
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 col-6 d-flex justify-content-center">
                                <div className="banner-icon">
                                    <img src="assets/images/Website-Bilder/Icons/Cube.svg" alt="Cube image" />
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 col-6 d-flex justify-content-center">
                                <div className="banner-icon">
                                    <img src="assets/images/Website-Bilder/Icons/device-desktop.svg" alt="Cube image" />
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 col-6 d-flex justify-content-center">
                                <div className="banner-icon">
                                    <img src="assets/images/Website-Bilder/Icons/device-mobile-rotated.svg" alt="Cube image" />
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 col-6 d-flex justify-content-center">
                                <div className="banner-icon">
                                    <img src="assets/images/Website-Bilder/Icons/world.svg" alt="Cube image" />
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-lg-end">
                            <div className="col-lg-11 col-md-12 col-sm-12 margin-top-lg-40">
                                <div className="banner-header">
                                    <h2 className="heading-text-84 title-text green-heading">CODE THE WAY</h2>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-12 col-sm-12">
                                <div className="banner-header">
                                    <h2 className="heading-text-84 title-text margin-bottom-lg-30">YOU WANT</h2>
                                    <div className="banner-section-paragraph">
                                        <p className="small-text-22 bold-paragraph-text">Von der eigenen Website über die mobile App bis zur Individualsoftware – wir entwickeln Anwendungen passend zu Ihren Anforderungen.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- Banner Section :: END --> */}

        {/* <!-- Second Banner Section :: START --> */}
        {/* <!-- <div className="codex-second-banner-section">
            <div className="container">
           
            </div>
        </div> --> */}
        {/* <!-- Second Banner Section :: END --> */}

        {/* <!-- Unlock Section :: START --> */}
        <div className="codex-unlock-section margin-bottom-lg-80 margin-bottom-sm-40">
            <div className="container">
                <div className="row margin-bottom-lg-50">
                    <div className="col-12">
                        <div className="title-section">
                            <p className="small-text-22 text-white sub-title-text">Unsere Leistungen</p>
                            <h2 className="heading-text-84 title-text text-white margin-bottom-lg-30">UNLOCK <span className="heading-text-84 title-text span-title margin-bottom-lg-30">NEW</span> POTENTIAL</h2>
                        </div>
                    </div>
                </div>
                <div className="row equal justify-content-center">
                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-sm-4 d-flex margin-bottom-lg-20">
                        <div className="unlock-cards card w-100">
                            <div className="card-body">
                                <div className="icon-title margin-bottom-lg-40">
                                    <div className="card-img-wrapper">
                                        <img src="assets/images/Website-Bilder/Icons/Glasses.svg" alt="Glasses-svg" />
                                    </div>
                                    <div className="card-title mb-0">
                                        <h5 className="small-text-24 sub-title-text">Training & Weiterbildung</h5>
                                    </div>
                                </div>
                                <p className="small-text-15 paragraph-dark-text">Wir bieten Schulungen in Mathematik, Python und R an, damit Du Kompetenzen ausbauen oder neue erlangen kannst.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-sm-4 d-flex margin-bottom-lg-20">
                        <div className="unlock-cards card w-100">
                            <div className="card-body">
                                <div className="icon-title margin-bottom-lg-40">
                                    <div className="card-img-wrapper">
                                        <img src="assets/images/Website-Bilder/Icons/diagram.svg" alt="Diagram-svg" />
                                    </div>
                                    <div className="card-title mb-0">
                                        <h5 className="small-text-24 sub-title-text">Entwicklung</h5>
                                    </div>
                                </div>
                                <p className="small-text-15 paragraph-dark-text">Bei uns bekommst Du alles – von Websites über Apps bis hin zu individuellen Anwendungen. Wir liefern Lösungen oder boosten Dein Team bei Engpässen. Unsere Fachgebiete: Python, Flutter, WordPress und R.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-sm-4 d-flex margin-bottom-lg-20">
                        <div className="unlock-cards card w-100">
                            <div className="card-body">
                                <div className="icon-title margin-bottom-lg-40">
                                    <div className="card-img-wrapper">
                                        <img src="assets/images/Website-Bilder/Icons/Data.svg" alt="Data-svg" />
                                    </div>
                                    <div className="card-title mb-0">
                                        <h5 className="small-text-24 sub-title-text">Data Science</h5>
                                    </div>
                                </div>
                                <p className="small-text-15 paragraph-dark-text">Wir verwandeln Daten in Wissen. Unsere Dienstleistungen umfassen Datenanalyse, Modellentwicklung, Vorhersagen und Dashboard-Erstellung. Gemeinsam gestalten wir datengetriebene Erfolgsgeschichten.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- Unlock Section :: END --> */}

        {/* <!-- GUI-Automatisierung Section :: START --> */}
        <div className="codex-gui-section margin-bottom-lg-100 margin-bottom-md-10 margin-bottom-sm-20">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="border-title">
                            <h3 className="border-webkit heading-text-40">GUI-AUTOMATISIERUNG</h3>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center">
                    <div className="col-lg-7 col-md-12 col-sm-12 d-flex justify-content-lg-start justify-content-center">
                        <div className="gui-section-img">
                            <img src="assets/images/Website-Bilder/Homepage/gui.png" alt="GUI Section Img" />
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-12 col-sm-12">
                        <div className="gui-section-title">
                            <div className="title-section">
                                <h2 className="heading-text-84 title-text margin-bottom-lg-30">
                                    <span className="heading-text-84 title-text">HIER</span><br />
                                    <span className="heading-text-84 title-text green-heading span-text margin-bottom-lg-30">KOMMT DIE</span><br /> 
                                    <span className="heading-text-84 title-text">ABKüRZUNG</span>
                                </h2>
                            </div>
                        </div>
                        <div className="banner-section-paragraph">
                            <p className="small-text-22 bold-paragraph-text">GUI-Automatisierung bietet die Möglichkeit, wiederkehrende Vorgänge effizient zu automatisieren und erledigt repetitive Vorgänge, damit sich Mitarbeiter anderen Aufgaben widmen können.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- GUI-Automatisierung Section :: END --> */}

        {/* <!-- Digitale section :: START --> */}
        <div className="codex-digital-section">
            <div className="container codex-container">
                <div className="row">
                    <div className="col-12">
                        <div class="border-title margin-bottom-sm-20 margin-bottom-md-40 small-device-section">
                            <h3 class="border-webkit heading-text-40">ZEITERFASSUNG, PROJEKT-& WISSENSMANAGEMENT</h3>
                        </div>
                        <div className="title-section">
                            <h2 className="heading-text-84 title-text text-white margin-bottom-lg-30">
                                <span className="heading-text-84 title-text text-white">DER</span> <br />
                                <span className="heading-text-84 title-text green-heading span-title span-text margin-bottom-lg-30">SCHRITT INS</span><br /> 
                                <span className="heading-text-84 title-text text-white">Digitale Büro</span>
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="banner-section-paragraph margin-top-lg-40">
                            <p className="small-text-22 bold-paragraph-text">In der heutigen Arbeitswelt ist der Online-Zugang zu Infos ein Muss. Projektmanagement-Tools sorgen für den nötigen Überblick über Aufgaben und Projekte, Wissensmanagement-Tools fördern gebündeltes Wissen und vermeiden isoliertes Wissen. Zeiterfassungs-Tools stehen für erhöhte Effizienz und die Minimierung von Zeitverlust. Perfekt gerüstet für die digitale Ära!</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 d-flex align-items-lg-end">
                        <div className="border-title large-device-section">
                            <h3 className="border-webkit heading-text-40">ZEITERFASSUNG, PROJEKT-& WISSENSMANAGEMENT</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- Digitale section :: END --> */}

        {/* <!-- EXCEL Section :: START --> */}
        <div className="codex-excel-section">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="border-title">
                            <h3 className="border-webkit heading-text-40">EXCEL</h3>
                        </div>
                    </div>
                </div>
                <div className="row margin-top-lg-80 margin-top-md-30 margin-top-sm-40 align-items-center">
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 margin-bottom-lg-20">
                        <div className="excel-img">
                            <img src="assets/images/excel-img.png" alt="Excel Image" />
                        </div>
                    </div>
                    <div className="col-xl-5 col-lg-6 col-md-12 col-sm-12">
                        <div>
                            <h2 className="heading-text-84 title-text margin-bottom-lg-30">
                                <span className="heading-text-84 title-text">Bye Bye</span> <br />
                                <span className="heading-text-84 title-text green-heading span-title span-text margin-bottom-lg-30">Excel</span><br /> 
                                <span className="heading-text-84 title-text">Chaos</span> <br />
                            </h2>
                            <div className="banner-section-paragraph">
                                <p className="small-text-22 bold-paragraph-text">Setze auf Python und R für smarte Lösungen. Schnell, flexibel und nutzerfreundlich. Mach Schluss mit komplizierten Tabellen. Wir kreieren individuelle Skriptlösungen, um Deine Arbeit zu erleichtern.</p>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- EXCEL Section :: END --> */}

        {/* <!-- Software Section :: START --> */}
        <div className="codex-software-section">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="border-title margin-bottom-sm-40">
                            <h3 className="border-webkit heading-text-40">SOFTWARE DEVELOPMENT</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container margin-top-lg-40  margin-top-sm-10">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-7 col-sm-12">
                        <div className="title-section margin-bottom-lg-50 margin-bottom-sm-10">
                            <h2 className="heading-text-84 text-white margin-bottom-lg-30">
                                <span className='heading-text-84'>Wunsch-</span> <br />
                                <span className="heading-text-84 title-text green-heading span-title span-text margin-bottom-lg-30">Programm</span><br />
                                <span className='heading-text-84 ml-5'>gesucht?</span></h2>
                        </div>
                        <div className="banner-section-paragraph margin-bottom-lg-40 margin-bottom-sm-10">
                            <p className="small-text-22 bold-paragraph-text">Wenn Standardlösungen nicht passen, stehen wir bereit, um  Lösungen zu schaffen. Unsere Expertise steht Dir bei der gesamten Programm-Entwicklung zur Seite, von der Planung bis zu Umsetzung und Hosting.</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-5 col-sm-12">
                        <div className="software-img small-device-img">
                            <img src="assets/images/software-pc.png" alt="Software Image" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- Software Section :: END --> */}

        {/* <!-- Traing Section :: START --> */}
        <div className="codex-taring-section">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="border-title">
                            <h3 className="border-webkit heading-text-40">TRAINING & WEITERBILDUNG</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row justify-content-center training-section">
                    <div className="col-xl-6 col-lg-8 col-md-12 col-sm-12 codex-taring-col">
                        <div className="title-section margin-bottom-lg-50">
                            <div>
                                <h2 className="heading-text-84 title-text text-white margin-bottom-lg-30">
                                    <span className="heading-text-84 title-text text-white">Learning</span> <br />
                                    <span className="heading-text-84 title-text green-heading span-title span-text margin-bottom-lg-30">Is the new</span><br /> 
                                    <span className="heading-text-84 title-text text-white">Knowing</span> <br />
                                </h2>
                                <div className="training-btn-section">
                                    <button className="btn footer-btn">Anfragen</button>
                                    <div className="banner-section-paragraph margin-bottom-lg-40">
                                        <p className="small-text-22 bold-paragraph-text">Unsere Schulungsangebote decken Mathematik, Python und R ab, um Dir die Möglichkeit zu geben, Deine Fähigkeiten zu erweitern oder neue zu erwerben.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- Traing Section :: END --> */}

        {/* <!-- Smart Section :: START --> */}
        <div className="codex-smart-section">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="border-title">
                            <h3 className="border-webkit heading-text-40">DATA</h3>
                            <h3 className="border-webkit heading-text-40">SCIENCE</h3>
                        </div>
                    </div>
                </div>
                <div className="row padding-section">
                    <div className="col-xl-4 col-lg-8 col-md-12 col-sm-12">
                        <div className="title-section">
                            <h2 className="heading-text-84 title-text text-white margin-bottom-lg-30">
                                <span className="heading-text-84 title-text green-heading span-text margin-bottom-lg-30">Smart</span><br />
                                <span className="heading-text-84 title-text text-white">Insights</span>
                            </h2>
                        </div>
                        <div className="banner-section-paragraph">
                            <p className="small-text-20 bold-paragraph-text">Wir verwandeln Daten in Wissen. Unsere Dienstleistungen umfassen Datenanalyse, Modellentwicklung, Vorhersagen und Dashboard-Erstellung.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- Smart Section :: END --> */}

        {/* <!-- CTA Section :: START --> */}
        <div className="codex-cta-section">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-10 col-md-12 col-sm-12 d-flex justify-content-lg-center">
                        <div className="title-section">
                            <h2 className="heading-text-84 title-text"><span className="heading-text-84">Bereit für den</span><br/><span className="heading-text-84 left-content">nächsten Schritt?</span></h2>
                        </div>
                    </div>
                    {/* <div className="col-lg-10 col-md-12 col-sm-12 d-flex justify-content-lg-end">
                        <div className="title-section">
                            <h2 className="heading-text-84 title-text margin-bottom-lg-30">nächsten Schritt?</h2>
                        </div>
                    </div> */}
                </div>
                <p className="small-text-16 paragraph-dark-text margin-top-lg-30">Wir freuen uns auf Deine Nachricht!</p>
                <form>
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="form-group margin-top-lg-20 margin-bottom-lg-40 margin-bottom-sm-20">
                                <input type="text" className="form-control" id="exampleInputBetreff" placeholder="Betreff" />
                            </div>
                            <div className="form-group margin-bottom-lg-40 margin-bottom-sm-20">
                                <input type="text" className="form-control" id="exampleInputName" placeholder="Name" />
                            </div>
                            <div className="form-group margin-bottom-lg-40 margin-bottom-sm-20">
                                <input type="mail" className="form-control" id="exampleInputEmail" placeholder="mail@beispiel.de" />
                            </div>
                            <div className="form-group margin-bottom-lg-40 margin-bottom-sm-20">
                                <input type="text" className="form-control" id="exampleInputPhone" placeholder="Telefonnummer" />
                            </div>
                            <div className="form-group margin-bottom-lg-40 margin-bottom-sm-20 check-box-input">
                                {/* <!-- <input type="text" class="form-control" id="exampleInputPhone" placeholder="" /> --> */}
                                <input type="checkbox" id="html"/>
                                <label className="small-text-16 paragraph-dark-text" for="html">Ich akzeptiere die Geschäftsbedingungen.</label>
                            </div>
                            {/* <p className="small-text-16 paragraph-dark-text">Ich akzeptiere die Geschäftsbedingungen.</p> */}
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="form-group margin-top-lg-20 margin-bottom-lg-40 margin-bottom-sm-20">
                                <textarea className="form-control" id="Nachricht" name="Nachricht" rows="12" cols="50" placeholder="Nachricht"></textarea>
                            </div>
                            <button className="btn navbar-btn cta-btn margin-bottom-sm-20" type="submit">Kontakt</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        {/* <!-- CTA Section :: END --> */}

        {/* <!-- Cookies Section :: START --> */}
        <CookiesModal/>

        {/* <div className="cookie-section">
            <div class="modal cookie-modal show" tabindex="-1" role="dialog" aria-modal="true" >
                <div className="modal-dialog modal-dialog-centered" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h3 className="modal-title small-text-20 sub-title-text">Cookie Notice</h3>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <i className="fa fa-times" aria-hidden="true"></i>
                      </button>
                    </div>
                    <div className="modal-body">
                        <p className="small-text-15 paragraph-dark-text">This website uses cookies to personalize content and analyse traffic in order to offer you a better experience.</p>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn navbar-btn" data-dismiss="modal">Accept</button>
                      <button type="button" className="btn navbar-btn" data-dismiss="modal">Decline</button>
                    </div>
                  </div>
                </div>
            </div> 
        </div> */}
        {/* <!-- Cookies Section :: END --> */}
        <Footer />
    </div>;
}

HomeCompo.propTypes = propTypes;
HomeCompo.defaultProps = defaultProps;
// #endregion

export default HomeCompo;