import React from 'react';
import Header from '../common/Header/Header';
import Footer from '../common/Footer/Footer';
// import '../../assets/css/style.css';
// import '../../assets/css/responsive.css';
// import '../../assets/css/bootstrap-css/bootstrap.min.css'
const propTypes = {};

const defaultProps = {};

const Jobs = () => {
    return <div>
<Header />
{/* <!-- Codex Jobbezeichnung Banner Section :: START--> */}
        <div className="codex-Jobbezeichnung-banner margin-bottom-lg-100 margin-bottom-sm-50">
            <div className="container-fluid">
                <div className="row careers-banner-bg">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="title-section">
                            <h2 className="title-text">
                                <span className="heading-text-84 text-white light-font">Werde Teil des</span><br/>
                                <span className="heading-text-84">
                                    <span className="heading-text-84 text-white lowercase-text margin-bottom-lg-30">co.dex </span>
                                    <span className="heading-text-84 title-text margin-bottom-lg-30 text-white light-font">Teams.</span>
                                </span>
                            </h2>
                            <div className="margin-top-lg-30">
                                <p className="heading-text-40 text-white bold-paragraph-text">Aktuell suchen wir nach:</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- Codex Jobbezeichnung Banner Section :: END--> */}

        {/* <!-- Job page Section :: START --> */}
        <div className="Jobbezeichnung-section margin-bottom-lg-100 margin-bottom-sm-50">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="title-section">
                            <h2 className="heading-text-50 title-text">Jobbezeichnung</h2>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="Jobbezeichnung-content margin-top-lg-50">
                            <h3 className="small-text-20 sub-title-text margin-bottom-lg-40">Stellenausschreibung 1</h3>
                            <p className="small-text-20 link-text margin-bottom-lg-20">
                                Wir bieten:
                            </p>
                            <p className="small-text-20 link-text margin-bottom-lg-30">
                                In der heutigen Arbeitswelt ist der Online-Zugang zu Infos ein Muss. Projektmanagement-Tools sorgen für den nötigen Überblick über Aufgaben und Projekte, Wissensmanagement-Tools fördern gebündeltes Wissen und vermeiden isoliertes Wissen. Zeiterfassungs-Tools stehen für erhöhte Effizienz und die Minimierung von Zeitverlust. Perfekt gerüstet für die digitale Ära!
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="Jobbezeichnung-contents margin-top-lg-50">
                            <h3 className="small-text-20 sub-title-text margin-bottom-lg-40">Stellenausschreibung 1</h3>
                            <p className="small-text-20 link-text margin-bottom-lg-20">
                                Wir bieten:
                            </p>
                            <p className="small-text-20 link-text margin-bottom-lg-30">
                                In der heutigen Arbeitswelt ist der Online-Zugang zu Infos ein Muss. Projektmanagement-Tools sorgen für den nötigen Überblick über Aufgaben und Projekte, Wissensmanagement-Tools fördern gebündeltes Wissen und vermeiden isoliertes Wissen. Zeiterfassungs-Tools stehen für erhöhte Effizienz und die Minimierung von Zeitverlust. Perfekt gerüstet für die digitale Ära!
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- Job page Section :: END --> */}

        {/* <!-- Job page Section :: START --> */}
        <div className="codex-Jobbezeichnung-section margin-bottom-lg-100 margin-bottom-sm-50">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="title-section">
                            <h2 className="heading-text-50 text-white title-text">Jobbezeichnung</h2>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="Jobbezeichnung-content margin-top-lg-50">
                            <h3 className="small-text-20 sub-title-text text-white margin-bottom-lg-40">Stellenausschreibung 1</h3>
                            <p className="small-text-20 text-white link-text margin-bottom-lg-20">
                                Wir bieten:
                            </p>
                            <p className="small-text-20 link-text text-white margin-bottom-lg-30">
                                In der heutigen Arbeitswelt ist der Online-Zugang zu Infos ein Muss. Projektmanagement-Tools sorgen für den nötigen Überblick über Aufgaben und Projekte, Wissensmanagement-Tools fördern gebündeltes Wissen und vermeiden isoliertes Wissen. Zeiterfassungs-Tools stehen für erhöhte Effizienz und die Minimierung von Zeitverlust. Perfekt gerüstet für die digitale Ära!
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="Jobbezeichnung-contents margin-top-lg-50">
                            <h3 className="small-text-20 sub-title-text text-white margin-bottom-lg-40">Stellenausschreibung 1</h3>
                            <p className="small-text-20 link-text text-white margin-bottom-lg-20">
                                Wir bieten:
                            </p>
                            <p className="small-text-20 link-text text-white margin-bottom-lg-30">
                                In der heutigen Arbeitswelt ist der Online-Zugang zu Infos ein Muss. Projektmanagement-Tools sorgen für den nötigen Überblick über Aufgaben und Projekte, Wissensmanagement-Tools fördern gebündeltes Wissen und vermeiden isoliertes Wissen. Zeiterfassungs-Tools stehen für erhöhte Effizienz und die Minimierung von Zeitverlust. Perfekt gerüstet für die digitale Ära!
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- Job page Section :: END --> */}

        {/* <!-- Job page Section :: START --> */}
        <div className="Jobbezeichnung-section margin-bottom-lg-100 margin-bottom-sm-50">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="title-section">
                            <h2 className="heading-text-50 title-text">Jobbezeichnung</h2>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="Jobbezeichnung-content margin-top-lg-50">
                            <h3 className="small-text-20 sub-title-text margin-bottom-lg-40">Stellenausschreibung 1</h3>
                            <p className="small-text-20 link-text margin-bottom-lg-20">
                                Wir bieten:
                            </p>
                            <p className="small-text-20 link-text margin-bottom-lg-30">
                                In der heutigen Arbeitswelt ist der Online-Zugang zu Infos ein Muss. Projektmanagement-Tools sorgen für den nötigen Überblick über Aufgaben und Projekte, Wissensmanagement-Tools fördern gebündeltes Wissen und vermeiden isoliertes Wissen. Zeiterfassungs-Tools stehen für erhöhte Effizienz und die Minimierung von Zeitverlust. Perfekt gerüstet für die digitale Ära!
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="Jobbezeichnung-contents margin-top-lg-50">
                            <h3 className="small-text-20 sub-title-text margin-bottom-lg-40">Stellenausschreibung 1</h3>
                            <p className="small-text-20 link-text margin-bottom-lg-20">
                                Wir bieten:
                            </p>
                            <p className="small-text-20 link-text margin-bottom-lg-30">
                                In der heutigen Arbeitswelt ist der Online-Zugang zu Infos ein Muss. Projektmanagement-Tools sorgen für den nötigen Überblick über Aufgaben und Projekte, Wissensmanagement-Tools fördern gebündeltes Wissen und vermeiden isoliertes Wissen. Zeiterfassungs-Tools stehen für erhöhte Effizienz und die Minimierung von Zeitverlust. Perfekt gerüstet für die digitale Ära!
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- Job page Section :: END --> */}

        {/* <!-- Contact Section :: START --> */}
        <div className="codex-contact-section codex-contact-Jobbezeichnung-section margin-bottom-lg-80">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h2 className="heading-text-84 title-text margin-bottom-lg-30"> 
                            <span className="heading-text-84 title-text margin-bottom-lg-30">Bewirb Dich</span>
                            <span className="heading-text-84 title-text span-title margin-bottom-lg-30">Jetzt</span>!
                        </h2>
                        <div className="training-btn-section contact-btn">
                            <button className="btn footer-btn">Kontakt</button>
                            <div className="banner-section-paragraph margin-bottom-lg-40">
                                <p className="heading-text-30 bold-paragraph-text">Wir freuen uns darauf, von Dir zu hören.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- Contact Section :: END --> */}
    <Footer />
    </div>;
}

Jobs.propTypes = propTypes;
Jobs.defaultProps = defaultProps;
// #endregion

export default Jobs;