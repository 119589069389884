import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const CookiesModal = () => {
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  const hideBackdrop = () => {
    const backdrop = document.getElementsByClassName('modal-backdrop');
    if (backdrop.length > 0) {
      backdrop[0].style.display = 'none';
    }
  };

  const handleAccept = () => {
    setModal(false);
    localStorage.setItem('cookiesAccepted', 'true');
    
    // Calculate the expiration date one month from the current date
    const expirationDate = new Date();
    expirationDate.setMonth(expirationDate.getMonth() + 1);
    
    // Store the expiration date in localStorage
    localStorage.setItem('cookiesExpiration', expirationDate.toISOString());
  };

  useEffect(() => {
    // Function to check and clear cookies
    const checkAndClearCookies = () => {
      const cookiesExpiration = localStorage.getItem('cookiesExpiration');
      if (cookiesExpiration) {
        const expirationDate = new Date(cookiesExpiration);
        const currentTime = new Date();
console.log(expirationDate,"expirationDate");
console.log(currentTime,"currentTime");
        if (currentTime >= expirationDate) {
          // Clear cookies when the expiration time is reached
          localStorage.removeItem('cookiesAccepted');
          localStorage.removeItem('cookiesExpiration');
        }
      }
    };

    const intervalId = setInterval(checkAndClearCookies, 3600000); // One hour interval

    // Clean up the interval when the component unmounts
    

    // Open the modal when the component is mounted (page load or refresh)
    if (localStorage.getItem('cookiesAccepted') == null) {
      setModal(true);
    } else {
      setModal(false);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div>
      <div className='cookie-section'>
        <Modal className='cookie-modal' isOpen={modal} toggle={toggle}>
          <ModalHeader>
            Cookie Notice
            <button type="button" className="close" onClick={toggle}>
              <i className="fa fa-times" aria-hidden="true"></i>
            </button>
          </ModalHeader>
          <ModalBody>
            <p className="small-text-15 paragraph-dark-text">This website uses cookies to personalize content and analyze traffic in order to offer you a better experience.</p>
          </ModalBody>
          <ModalFooter>
            <Button className="btn navbar-btn" onClick={handleAccept}>
              Accept
            </Button>
            <Button className="btn navbar-btn" onClick={toggle}>
              Decline
            </Button>
            {/* Add other modal action buttons here */}
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
};

export default CookiesModal;
