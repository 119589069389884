import React from 'react';
import Header from '../common/Header/Header';
import Footer from '../common/Footer/Footer';
// import '../../assets/css/style.css';
// import '../../assets/css/responsive.css';
// import '../../assets/css/bootstrap-css/bootstrap.min.css'
const propTypes = {};

const defaultProps = {};

const Impressum = () => {
    return <div>
<Header />
        {/* <!-- Impressum Section :: START --> */}
        <div className="codex-impressum-section margin-top-lg-80 margin-bottom-lg-80">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-12 margin-bottom-sm-20">
                        <div className="footer-content">
                            <div className="footer-header margin-bottom-lg-40">
                                <h3 className="small-text-20 sub-title-text">Hauptstandort</h3>
                            </div>
                            <div className="footer-links">
                                <ul className="list-unstyled">
                                    <li className="margin-bottom-lg-20">
                                        <p className="small-text-20 link-text">co·dex GmbH</p>
                                    </li>
                                    <li className="margin-bottom-lg-20">
                                        <p className="small-text-20 link-text">Erlenring 25</p>
                                    </li>
                                    <li className="margin-bottom-lg-20">
                                        <p className="small-text-20 link-text">38458 Velpke</p>
                                    </li>
                                    <li className="margin-bottom-lg-20">
                                        <a href="tel:+491724312342">
                                            <p className="small-text-20 link-text">Telefon: +49 1724 312342</p>
                                        </a>
                                    </li>
                                    <li className="margin-bottom-lg-20">
                                        <a href="mailto:info@codex.de">
                                            <p className="small-text-20 link-text">E-Mail: info@codex.de</p>
                                        </a>
                                    </li>
                                    <li className="margin-bottom-lg-20">
                                        <p className="small-text-20 link-text">Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz: DE342814277</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 margin-bottom-sm-20">
                        <div className="footer-content">
                            <div className="footer-header margin-bottom-lg-40">
                                <h3 className="small-text-20 sub-title-text">Bildmaterial</h3>
                            </div>
                            <div className="footer-links">
                                <ul className="list-unstyled">
                                    <li className="margin-bottom-lg-20">
                                        <p className="small-text-20 link-text">© co·dex GmbH; pexels.com, midjourney.com, icons8.de, unsplash.com</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12 margin-bottom-sm-20">
                        <div className="footer-content">
                            <div className="footer-header margin-bottom-lg-40">
                                <h3 className="small-text-20 sub-title-text">Rechtlicher Hinweis</h3>
                            </div>
                            <div className="footer-links">
                                <ul className="list-unstyled">
                                    <li className="margin-bottom-lg-20">
                                        <p className="small-text-20 link-text">Die Codex GmbH ist bestrebt, durch Aktualisierungen die Richtigkeit und Aktualität aller auf ihrer Website präsentierten Informationen und Daten sicherzustellen. Die Codex GmbH übernimmt jedoch keine Haftung oder Gewähr für etwaige Satzfehler sowie für die Richtigkeit und Aktualität der Eintragungen.</p>
                                    </li>
                                    <li className="margin-bottom-lg-20">
                                        <p className="small-text-20 link-text">Der Inhalt der Codex Website unterliegt dem Schutz des Urheberrechts. Vervielfältigungen und andere Formen der Verwertung von Informationen oder Daten, einschließlich der Verwendung von Texten, Textteilen oder Bildmaterial, erfordern, sofern nicht anders angegeben, die vorherige Zustimmung der Codex GmbH.</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- Impressum Section :: END --> */}
<Footer />
    </div>;
}

Impressum.propTypes = propTypes;
Impressum.defaultProps = defaultProps;
// #endregion

export default Impressum;