import React, { useState } from "react";
// import '../../../assets/css/style.css';
import HeaderLogo from "../../../assets/images/header-logo.png";
import { Link } from "react-router-dom";

function Header() {
  const [collapsed, setCollapsed] = useState(true);

  const toggleNavbar = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div>
      {/* <Navbar classNameName='codex-header-section' color="faded" light expand="md">
        <NavbarBrand href="/" classNameName="mr-auto">
          <img src={HeaderLogo} alt="Header Logo" />
        </NavbarBrand>
        <NavbarToggler onClick={toggleNavbar} classNameName="mr-2" />
        <Collapse isOpen={!collapsed} navbar>
          <Nav navbar>
            <NavItem>
              <NavLink href="/components/">Components</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="https://github.com/reactstrap/reactstrap">GitHub</NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar> */}
      {/* <!-- Header Section :: START --> */}
      <header className="codex-header-section">
        <div className="container">
          <div className="row">
            <div className="col-12 p-0">
              <div className="codex-navbar">
                <nav className="navbar navbar-expand-lg navbar-expand-md navbar-light align-items-center p-0">
                {/* <div className="navbar-brand">
                    <img src={HeaderLogo} alt="Codex Logo" />
                  </div> */}
                  <Link to="/">
                  <div className="navbar-brand">
                    <img src={HeaderLogo} alt="Codex Logo" />
                  </div>
                  </Link>
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <i class="fa fa-bars" aria-hidden="true"></i>
                  </button>

                  <div
                    className="collapse navbar-collapse"
                    id="navbarSupportedContent"
                  >
                    <ul className="navbar-nav ml-auto">
                      <li className="nav-item">
                        <a
                          className="nav-link active small-text-16"
                          aria-current="page"
                          href="#"
                        >
                          Leistungen
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link small-text-16" href="#">
                          About
                        </a>
                      </li>
                      <button className="btn navbar-btn" type="submit">
                        Kontakt
                      </button>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* <!-- Header Section :: END --> */}
    </div>
  );
}

export default Header;
